.db-c {
  margin: 10px 0 100px 0;
}

.dashbboard-cover {
  width: 100%;
  height: auto;
  margin: auto;
}

.card-i {
  /* border-radius: 5px; */
  /* background-color: #ebffd0; */
  /* color: #cee7ff; */
  /* color: #ffd8d1;
  color: #e6e6e6; */
}

.tab-t {
  border-bottom: 1.5px solid #f2f2f2;
  cursor: pointer;
}

.tab-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #f2f2f2;
  overflow-y: hidden;
  overflow-x: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tab-top::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-top {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tab-t.active {
  color: #0c1238;
  font-weight: 510;
  border-bottom: 1.5px solid #0c1238;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
  position: relative;
}

.tab-body {
  width: 100%;
  height: auto;
  position: relative;
}

.tab-content__map {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #fff;
  position: relative;
}

.tab-content__map::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-content__map {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.state-map {
  width: auto;
  height: 100vh;
  background: #fff;
  position: relative;
  /* transform: scale(2); */
}

/* stats */
.stats-cover {
  background: #f9f9f9;
}

.stats-i__c {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px #eeeeeede;
}

.stats-t,
.stats-name {
  color: #001755;
  font-weight: 600;
}

.stats-right {
  color: #82c91e;
  font-weight: 510;
}

.stats-img {
  width: 30px;
  height: 30px;
}

.stats-container {
}

/* Legend */

.z-card {
  background: #f9f9f9;
}

.z-c {
}

.dsk-c__c {
  position: relative;
}

.chart-t__c {
  position: absolute;
  top: 10px;
  left: 10px;
}

.chart-t__t {
  font-size: 15px;
  color: #001755;
  font-weight: 510;
}

.ct-t__c {
  width: 100%;
  font-size: 13px;
  color: #757575;
}


@media screen and (max-width:768px){
  .mobile{
      display:none
  }
}