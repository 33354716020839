.layout-cover {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: #d2d2d2;
  position: fixed;
  top: 0;
  left: 0;
}

.l-content {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  top: 0;
  left: 0;
}

.side-nav {
  width: auto;
  height: 100vh;
  background: #f8f9fa;
  z-index: 100;
  border-right: 2px solid #e9e9e9;
}

.l-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.l-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
