.top-nav {
  width: 100%;
  z-index: 1000;
  position: sticky;
  top: 0;
  left: 0;
}

.left-i__c {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  background: #ffeeceb7;
  box-shadow: 0 2px 5px #eeeeee91;
}

.left-t__title {
  color: #1d255a;
  font-size: 16px;
  font-weight: 510;
}

.left-t__caption {
  font-weight: 400 !important;
  font-size: 13px;
  color: #757575;
}

.right-date__c {
  width: 110px;
  border-right: 1px solid #cacaca;
}

.right-date__c {
  /* color: #cacaca; */
}

.right-profile__c {
  width: 100%;
}

.right-c {
  position: relative;
}
.right-date {
  margin: 0 0 0 0;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
}

.profile-cover {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
}

.profile-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.profile-i {
  margin: 0 30px 0 0;
}

.d-tc {
  margin: 5px 0;
}
.mobile-nav__btn {
  display: none !important;
}

@media (max-width: 500px) {
  .left-t__caption {
    display: none;
  }
  .left-i__c {
    display: none;
  }

  .left-t__title {
    font-size: 15px;
  }

  .mobile-nav__btn {
    display: block !important;
  }
}
