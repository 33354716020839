.login-c {
  width: 100%;
  /* background: #fff; */
  height: 100vh;
  background-image: url("../../assets/svg/laptopbg2.svg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  /* background: #f0f0f0; */
}

/* .cc-cover {
  margin: 50px 0;
} */

.lp-bg {
  width: 400px;
  height: 300px;
  top: 0;
  left: 0;
  transform: scale(1.3);
  /* z-index:1 !important; */
}

.lp-bg img {
  transform: scale(1.2);
}

.hero-brand {
  font-size: 30px;
  font-weight: bolder;
}

.hero-caption {
  font-size: 25px;
}

.auth-form {
  width: 100%;
  height: auto;
  z-index: 900000 !important;
  margin-top: 0;
  /* box-shadow: 0 3px 10px #eeeeeede; */
}

.form-control.form-input {
  height: 50px;
}

.auth-title {
  font-size: 18px;
  font-weight: 600;
}

.auth-caption {
  font-size: 15px;
  color: #a0a0a0;
}

.auth-helper {
  font-size: 14px;
  color: #575757;
}

.auth-email {
  color: dodgerblue;
  font-size: 14px;
}

.error-helper {
  color: tomato;
  font-size: 14px;
}

.form-label {
  font-size: 14px;
}

.form-input.custom {
  width: 80%;
  height: 100%;
  border: none !important;
  outline: none !important;
}

/* @media (min-width: 600px) {
  .auth-form {
    max-width: 400px;
    width: 400px;
  }
} */

@media (max-width: 600px) {
  .lp-bg img {
    display: none;
  }
  .cod-view {
    margin-top: 400px;
    display: none !important;
  }
  .cod-fill {
    margin-bottom: 450px;
  }
  .relly-fill {
    flex-direction: column-reverse;
  }
}
