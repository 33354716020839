.brand {
  background: #0c1238;
}

.brand-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dee2e6;
}

.profile-alias {
  font-size: 16px;
  font-weight: 600;
  color: #0c1238;
}

.side-nav__ic {
  width: 80px;
  height: 100vh;
  background: inherit;
  transition: width 50ms ease-in-out;
}

.side-nav__ic:hover {
  width: 230px;
}

.nav-link__text {
  color: #0c1238;
  font-weight: 510;
  font-size: 15px;
}

.nav-link__text:hover {
  transition: 100ms ease-in-out;
}

.brand-name {
  font-size: 18px;
  font-weight: 600;
  /* color: #0c1238; */
}


@media screen and (max-width:600px) {
  .side-nav{
     display: none;
  }
}