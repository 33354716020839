.scale-btn__c {
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0 2px 5px #eeeeeede;
  z-index: 10 !important;
  background: #fff;
}
.scale-btn {
  cursor: pointer;
  border: none !important;
  outline: none !important;
  background: #fff;
  display: block;
}

.scale-btn:nth-child(2) {
  border-top: 1px solid #eee;
}
