.z-c {
  border-radius: 50%;
  color: #fff;
  width: 50px;
  height: 50px;
  background: #001755;
}

.z-c > div {
  font-weight: 510;
}

.z-text {
  color: #001755;
  font-weight: 600;
}
