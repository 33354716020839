* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

html,
body {
  width: 100%;
  height: 100vh;
  background: #f8f9fa !important;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: #0000000f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 14px;
}

.ant-picker-input input {
  margin-top: 0 !important;
}
